import React from "react";


const ForgetPassword = () => {
    return (
    <form action="">        
        <div>
            <label for="email" class="block text-gray-800 font-bold">Email:</label>
            <input type="text" name="email" id="email" placeholder="@email" class="w-full border border-gray-300 py-2 pl-3 rounded mt-2 outline-none focus:ring-indigo-600 :ring-indigo-600" />
            
        </div>
            <button class="cursor-pointer py-2 px-4 block mt-6 bg-indigo-500 text-white font-bold w-full text-center rounded">Send My Password</button>
    </form>

    );

}
export default ForgetPassword;