import React,{useEffect,useState,useRef} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import validator from "validator";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";

import { cataloguesData,entitiesData,entityButtonsData,entityButtonSubsData } from "../../data/posmenu";
import "./../CSS/POSMenu.css"




const POSMenuSort = () => {
  let  gridcomp,listCatalogue,listEntity,listEntityButton,listEntityButtonsub;
  const [CatalogueData, setCatalogue] = useState([]);
  const [EntityData, setEntity] = useState([]);
  const [EntityButtonData, setEntityButton] = useState([]);
  const [EntityButtonSubData, setEntityButtonSub] = useState([]);

  const { currentMode } = useStateContext();
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  const test=()=>{console.log("test");}


  let CatalogueFields = { text: "CatalogName", value: "IID" };
  let EntityFields = { text: "EntityName", value: "IID",color:"PanelColor" };
  let EntityButtonFields = { text: "EntityButtonName", value: "IID" };
    
let getCatalogues = () => { 
  cataloguesData((dataorder)=>{ 
    setCatalogue(dataorder);// leading data to the ListBox
  });     
}
let getEntities= (iid) => { 
  //console.log(iid);
  entitiesData(iid,(dataorder)=>{ 
    setEntity(dataorder);// leading data to the ListBox
    setEntityButton([]);// leading data to the ListBox
    setEntityButtonSub([]);
  });     
}
let getEntityButtons= (iid) => { 
  //console.log(iid);
  entityButtonsData(iid,(dataorder)=>{ 
    setEntityButton(dataorder);// leading data to the ListBox
    setEntityButtonSub([]);
  });     
}
let getEntityButtonSubs= (iid) => { 
  //console.log(iid);
  entityButtonSubsData(iid,(dataorder)=>{ 
    setEntityButtonSub(dataorder);// leading data to the ListBox
  });     
}

useEffect(() => {
  getCatalogues();
  
}, []);

const showSelectedCatalogue=(arg)=>{
  //console.log(listboxobj.getSortedList());
  //let list = listboxobj.getSortedList().findIndex(item=>item.IID===arg.value[0]); 
  let catalogueIID=arg.value[0];
  //console.log(catalogueIID);
  getEntities(catalogueIID);

  //element.classList.add("mystyle");  
}
const showSelectedEntity=(arg)=>{
  //console.log(listboxobj.getSortedList());
  //let list = listboxobj.getSortedList().findIndex(item=>item.IID===arg.value[0]); 
  let entityIID=arg.value[0];
  getEntityButtons(entityIID);
  
}
const showSelectedEntityButton=(arg)=>{
  let entityButtonIID=arg.value[0];
  getEntityButtonSubs(entityButtonIID);
}

const showSelectedEntityButtonBus=(arg)=>{
  //console.log(listboxobj.getSortedList());
  //let list = listboxobj.getSortedList().findIndex(item=>item.IID===arg.value[0]); 
}


const fillColoursEntity=()=>{
   /* [...document.getElementById('divEntity').children[6].children[2].children].map(child => {
                                                                                          child.style.backgroundColor='#ccc';
                                                                                          child.style.color='#abc';

    });*/
    //alert(divEntity.innerText)

}

const reset=()=>{

  
}

const save=()=>{

  
}




    return(
      <div className={"m-2 md:mx-5 mt-2 p-2 md:p-5 rounded-2xl " + bgcolor} >
      <Header title="POS Menu" />   
        <div>
          <div id="divCatalogue" className="block column4 line1">
              <button onClick={reset} className="e-btn bg-indigo-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase float-right">reset changes</button>
              <button onClick={save} className="e-btn bg-teal-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase float-right">save changes</button>
              <ListBoxComponent ref={(scope) => listCatalogue = scope} dataSource={CatalogueData} fields={CatalogueFields} change={showSelectedCatalogue}  />
          </div>
          <br/>
          <div id="divEntity" className="block column6 line2">
              <ListBoxComponent ref={(scope) => listEntity = scope} dataSource={EntityData}  fields={EntityFields} allowDragAndDrop="true" change={showSelectedEntity} />
          </div>
          <br/>
            
          <div id="divEntityButton" className="block column4 line3">
              <ListBoxComponent ref={(scope) => listEntityButton = scope} dataSource={EntityButtonData} fields={EntityButtonFields} allowDragAndDrop="true" change={showSelectedEntityButton} />
          </div>
          <br/>          
            
          <div id="divEntityButtonSub" className="block column3 line3">
              <ListBoxComponent ref={(scope) => listEntityButtonsub = scope} dataSource={EntityButtonSubData} fields={EntityButtonFields} allowDragAndDrop="true" change={showSelectedEntityButtonBus} />
          </div>


        </div>

    </div>
    )
  }
  
  export default POSMenuSort;
  