import React,{useState} from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { useStateContext } from "../contexts/ContextProvider";

import {useToken} from './../useToken';
import { Login } from "../pages";


const UserProfile = () => {
  const { currentColor } = useStateContext();
  const { setIsClicked, initialState } = useStateContext();


  const { removeToken } = useToken();

  function Logout() {    
    removeToken();
    window.location.reload(false)
  }


  return (
    <div className="nav-item absolute right-1 top-12 bg-white dark:bg-[#42464D] p-8 rounded-lg w-45">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200"></p>
        <Button color="white" bgColor={currentColor}  text="Close" borderRadius="10px" width="full" onClick={() => setIsClicked(initialState)} />
      </div>
      <div className="mt-2">
        <Button color="white" bgColor={currentColor} text="Logout" borderRadius="10px" width="full" onClick={Logout} />
      </div>
      
    </div>
    
  );
};

//bgColor, color, bgHoverColor, size, text, borderRadius, width, onClick
export default UserProfile;
