export { default as Orders } from "./Orders";
export { default as Dashboard } from "./Dashboard";
export { default as Staff } from "./Staff";

export { default as POSSort } from "./pos/MenuSort";
export { default as POSCatalogue } from "./pos/Catalogue";
export { default as POSMenu } from "./pos/Menu";
export { default as POSEntity } from "./pos/Entity";
export { default as POSEntityButton } from "./pos/EntityButton";
export { default as POSEntityButtonSub } from "./pos/EntityButtonSub";
export { default as POSGlobalType } from "./pos/GlobalType";

export { default as QRMenu } from "./QRMenu";
export { default as Logs} from "./logs/index"

export { default as Login } from "./User/Login";
export { default as ForgetPassword } from "./User/ForgetPassword";
export { default as Logout } from "./User/Logout";