import React,{useEffect,useState} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';

import { ordersData, ordersGrid,orderItemsGrid } from "../data/orders";
import { Header } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { toCurrency } from "../tools/currency";

const Orders = () => {
  let  gridcomp;
  const { currentMode } = useStateContext();
  const [Orders, setOrders] = useState([]);
  const [OrderItems, setOrderItems] = useState([]);
  const [BOrders, setBOrders] = useState([]);
  
  const [selectedProduct, setSelectedProduct] = useState([]);



  //modal
  const [visibility, setDialogVisibility] = useState(false);
  
  const dialogClose = ()=> {
    setDialogVisibility(false);
  }





  const [info, setInfo] = useState({Total:0,Vat:0,WVat:0,NVat:0,Card:0,Cash:0});
  
  
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  //const toolbarOptions = ['Edit', 'Delete', 'Update', 'Cancel','Search']; toolbar={toolbarOptions}

  const editSettings = {allowEditOnDblClick: false};

  const actionBegin = (args) => {
    if (args.requestType === 'save') {
      //alert(args.rowData.OrderID);
    }
  };

  const rowSelected = (args) => {  
    //alert(args.data.UserName)
    setSelectedProduct(args.data);
  }
  const ShowAll=()=>{
    document.getElementById("rdPaid").checked = false;
    document.getElementById("rdUnpaid").checked = false;
    
    document.getElementById("rdCash").checked = false;
    document.getElementById("rdCard").checked = false;

    //console.log(gStatus);
    setOrders(BOrders);
    calculateTotals(BOrders);
  }

  const Details=()=>{
    if(gridcomp.selectedRowIndex>=0)
    {
      var btnSendEmail = document.getElementById('sendEmail')
      if(btnSendEmail!==undefined && btnSendEmail.getAttribute('listener') !== 'true') {
        btnSendEmail.addEventListener('click',()=>{alert("Still in development!")});
        btnSendEmail.setAttribute('listener', 'true');
      }



      setDialogVisibility(true);
      setOrderItems(selectedProduct.sub);
      document.getElementById('orderitemtotal').innerText='Total : ' + toCurrency(selectedProduct.total);
      document.getElementById('orderitemvat').innerText='VAT : ' + toCurrency(selectedProduct.vat);
    }
  }
  const ShowFiltered=()=>{
    
    let bOrders = BOrders
    if(document.getElementById("rdPaid").checked === true){      
      bOrders = bOrders.filter((item) => item.Status > 2);
    }else if(document.getElementById("rdUnpaid").checked === true){     
      bOrders = bOrders.filter((item) => item.Status < 3);
    }else if(document.getElementById("rdCash").checked === true){  
      bOrders = bOrders.filter((item) => item.Payment === 1);
    }else if(document.getElementById("rdCard").checked === true){
      bOrders = bOrders.filter((item) => item.Payment === 3);
    }
    
    setOrders(bOrders);
    calculateTotals(bOrders);
  }
  const calculateTotals=(dataorder)=>{
    let WVat = dataorder.reduce((acc,item) => acc + parseFloat(item.WVat),0);     
    let NVat = dataorder.reduce((acc,item) => acc + parseFloat(item.NVat),0);         
    let vat = dataorder.reduce((acc,item) => acc + parseFloat(item.vat),0);
    let total = dataorder.reduce((acc,item) => acc + parseFloat(item.total),0);
    let cash = dataorder.reduce((acc,item) => acc + ((item.Payment===1?parseFloat(item.NVat)+parseFloat(item.WVat):0)),0);
    let card = dataorder.reduce((acc,item) => acc + ((item.Payment===3?parseFloat(item.NVat)+parseFloat(item.WVat):0)),0);

    
    info.Vat = toCurrency(vat);
    info.WVat = toCurrency(WVat);
    info.NVat = toCurrency(NVat);
    info.Cash = toCurrency(cash);
    info.Card = toCurrency(card);
    info.Total = toCurrency(total);
    setInfo(info);
  }

  let getOrders = () => {
    setOrders([]);//clean the grid before loading      
    ordersData((dataorder)=>{ 
      setBOrders(dataorder);// leading data to Backup
      setOrders(dataorder);// leading data to the grid

      calculateTotals(dataorder);

      
    });     
  }

  

  return (
    <div className={"m-2 md:m-5 mt-24 p-2 md:p-5 rounded-2xl " + bgcolor} >
      <Header title="Orders" />   
      
      <div className="grid grid-cols-5 gap-2">       
        <div className="col-span-3">
          <input className="e-btn " type="button" id="loadorders" name="loadorders" value="LOAD ORDERS" onClick={getOrders}  />
          <input className="e-btn " type="button" id="all" name="all" value="all" onClick={ShowAll} />

          <div id="gStatus" className="e-btn-group">
              <input type="radio" id="rdPaid" name="gFilter" value="paid" onChange={ShowFiltered}  />
              <label className="e-btn" htmlFor="rdPaid">PAID</label>

              <input type="radio" id="rdUnpaid" name="gFilter" value="unpaid" onChange={ShowFiltered}  />
              <label className="e-btn" htmlFor="rdUnpaid">UNPAID</label>

              <input type="radio" id="rdCard" name="gFilter" value="card" onChange={ShowFiltered}  />
              <label className="e-btn" htmlFor="rdCard">CARD</label>
              
              <input type="radio" id="rdCash" name="gFilter" value="cash" onChange={ShowFiltered}  />
              <label className="e-btn" htmlFor="rdCash">CASH</label>
          </div>
          <input className="e-btn " type="button" id="all" name="all" value="Details" onClick={Details}  />
        </div>
        <div className="col-span-2 grid grid-cols-2 gap-0.5">
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Card : <b>{info.Card}</b></label>
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Cash : <b>{info.Cash}</b></label>   
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">With VAT : <b>{info.WVat}</b></label>
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">VAT Total  : <b>{info.Vat}</b></label>     
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Without VAT : <b>{info.NVat}</b></label>
          <label className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md">Total : <b>{info.Total}</b></label>   
        </div>
      </div>  
      
      <GridComponent ref={g => gridcomp = g} allowPaging allowSorting  editSettings={editSettings}  created={getOrders} dataSource={Orders}
                                            actionBegin={actionBegin.bind(this)}  rowSelected={rowSelected}   >
        <ColumnsDirective>
          {ordersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Toolbar]} />
      </GridComponent>

      <DialogComponent isModal={true}  id="modalDetails" showCloseIcon={true} visible={visibility} close={dialogClose} header="Order Details" 
      cssClass="e-fixed border-8 border-zing-500" width='600px' >
        <div >
          <div className="grid grid-cols-5 gap-2">
            <input type="text" id="txtEmail" placeholder="Email address to send as an Invoice"
              className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-3 " />
            <input type="button" id="sendEmail" defaultValue='Send Email'
              className="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-zing-500 hover:border-transparent rounded"
              /><div>
          <div id="orderitemtotal" className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md"></div> 
          <div id="orderitemvat" className="e-label text-sm text-right bg-zinc-50 pr-2 drop-shadow-md"></div> 
          </div>
          </div>
          <GridComponent allowPaging allowSorting  editSettings={editSettings} dataSource={OrderItems}>
          <ColumnsDirective>
          {orderItemsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Toolbar]} />
      </GridComponent>

        
        
        
        </div>
      </DialogComponent>
    </div>
  );
};

export default Orders;
