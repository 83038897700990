import React, { useState }  from "react";
import { useEffect, useRef } from 'react';
import { DashboardLayoutComponent, PanelDirective, PanelsDirective } from '@syncfusion/ej2-react-layouts';
import { MdCalculate,MdOutlinePayments } from "react-icons/md";
import { GoListOrdered } from "react-icons/go";
import moment from 'moment';

import './CSS/dashboard.css';

import { useStateContext } from "../contexts/ContextProvider";


const Dashboard = () => {
    const { currentColor, currentMode } = useStateContext();

    const dashboardObj = useRef(null);
    const cellSpacing = [4, 4];
       

    const [stotal, setStotal] = useState(1472);
    const [vtotal, setVtotal] = useState(48.4);
    //const [sStart, setSstart] = useState(moment().subtract(1, "days").format("DD/MM/YYYY HH:mm"));

    const [tcard, setTcard] = useState(14.2);
    const [tcash, setTcash] = useState(48.4);

    const orderTotal = () => {
      //setStotal(1000);
      return(
            <div className="card">
                <span className="e-icons "><GoListOrdered /></span>
               
                <div className="card-content text">Paid Total :
                <span className="card-content number"> 62</span></div>
                <div className="card-content text">Canceled Orders:
                <span className="card-content number"> 3</span></div>
                <div className="card-content text">Total Orders:
                <span className="card-content number"> 68</span></div>
            </div>
        );
    }
    
    const sessionTotal = () => {
      //setStotal(1000);
      return(
            <div className="card">
                <span className="e-icons "><MdCalculate /></span>
                <div className="card-content text">VAT Total:
                <span className="card-content number"> £{vtotal.toFixed(2)}</span></div>
                <div className="card-content text">Total:
                <span className="card-content number"> £{stotal.toFixed(2)}</span></div>
            </div>
        );
    }
    
    const PaymentType = () => {
      //setStotal(1000);
      return(
            <div className="card">
                <span className="e-icons "><MdOutlinePayments /></span>                
                <div className="card-content text">Card:
                <span className="card-content number"> £{tcard.toFixed(2)}</span></div>
                <div className="card-content text">Cash:
                <span className="card-content number"> £{tcash.toFixed(2)}</span></div>
            </div>
        );
    }

    const colChart = () => {
      return(<div style={{ height: "100%", width: "100%" }}></div>)
    }
    const pieChart2 = () => {
      return(<div style={{ height: "100%", width: "100%" }}></div>)
    }
    const pieChart3 = () => {
      return(<div style={{ height: "100%", width: "100%" }}></div>)
    }










    return (<div>
      <div id="default_target" className="control-section">
         
      <DashboardLayoutComponent id="analytic_dashboard" cellAspectRatio={100/85} cellSpacing={cellSpacing} columns={6}>
            <PanelsDirective>
              <PanelDirective sizeX={2} sizeY={1} row={0} col={0} content={orderTotal} header="<div>Orders</div>"></PanelDirective>
              <PanelDirective sizeX={2} sizeY={1} row={0} col={2} content={sessionTotal} header="<div>Sessions</div>"></PanelDirective>
              <PanelDirective sizeX={2} sizeY={1} row={0} col={4} content={PaymentType} header="<div>Payment</div>"></PanelDirective>
              <PanelDirective sizeX={2} sizeY={2} row={1} col={0} content={colChart} header="<div>Active Visitors</div>"></PanelDirective>
              <PanelDirective sizeX={2} sizeY={2} row={1} col={2} content={colChart} header="<div>Regional Map</div>"></PanelDirective>
              <PanelDirective sizeX={2} sizeY={2} row={1} col={4} content={colChart} header="<div>Visitors by Type</div>"></PanelDirective>
              <PanelDirective sizeX={2} sizeY={2} row={3} col={0} content={colChart} header="<div>Useage Statistics</div>"></PanelDirective>
              <PanelDirective sizeX={4} sizeY={2} row={3} col={2} content={colChart} header="<div>Traffic History</div>"></PanelDirective>
            </PanelsDirective>
          </DashboardLayoutComponent>
      </div>
  </div>);
};
export default Dashboard;