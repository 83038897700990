import React from "react";

import { useStateContext } from "../contexts/ContextProvider";

const Header = ({ title,category='' }) => {
  const { currentColor } = useStateContext();

  return (
    <div className=" mb-10">
      <span className="text-lg text-gray-400">{category}</span>
      <span className="text-3xl font-extrabold tracking-tight" style={{ color: currentColor }}>
        {title}
      </span>
    </div>
  );
};

export default Header;
