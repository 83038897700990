

export const MenuColumns = [
    {text:2,value:354},
    {text:3,value:234},
    {text:4,value:174},
    {text:5,value:138},
    {text:6,value:114},
    {text:7,value:96},
]

export const MenuLines=[
    {text:1,value:24},
    {text:2,value:40},
    {text:3,value:56},
    {text:4,value:72},
    {text:5,value:88},
]
export const ButtonTypes=[
    {text:'Simple Item',value:0},
    {text:'Complex Item',value:1},
    //{text:'NoItem',value:2},
    //{text:'ExtraItem',value:3},
    //{text:'NewLine',value:4},
    //{text:'ReturnItem',value:5},
    //{text:'CancelItem',value:6},
    //{text:'Amount Addition',value:7},
    //{text:'Percent Addition',value:8},
    //{text:'Custom Addition',value:9},
    //{text:'Amount Deduction',value:10},
    //{text:'Percent Deduction',value:11},
    //{text:'Custom Deduction',value:12},
    //{text:'Refund',value:13},
    //{text:'Menu',value:14},
    //{text:'Deal',value:15},  
]

export const ButtonDiscTypes=[
    {text:'Simple Item',value:0},
    {text:'Percent Addition',value:8},
    {text:'Custom Addition',value:9},
    {text:'Percent Deduction',value:11},
    {text:'Custom Deduction',value:12},
    
]

export const VAT=[
    {text:'0',value:0},
    {text:'20',value:20},
]

export const PAD=[
    {text:'Menu Item',value:0},
    {text:'PAD Item',value:1},
]



export const MiddleColours = {
    'Middle':[
        "#A9A9A9", // Silver Gray
        "#808080", // Gray
        "#3CB371", // Sea Green
        "#4876AA", // Teal
        "#2F4F4F", // Dark Slate Gray
        "#C792EA", // Lavender Blush
        "#CD6133", // Mahogany
        "#9ACD32", // Yellow Green
        "#FFD700", // Gold
        "#DC143C", // Crimson
        "#7B68EE", // Slate Blue
        "#00CED1", // Dark Turquoise
        "#6B8E23", // Olive Green
        "#9F79EE", // Lavender
        "#FF69B4", // Hot Pink
        "#C0C0C0", // Silver
        "#D2B48C", // Tan
        "#DAA520", // Goldenrod
        "#BA55D3", // Medium Orchid
        "#9400D3", // Dark Violet
        "#FFA500", // Orange
        "#8B4513", // Saddle Brown
        "#DAA520", // Goldenrod
        "#CD5B45", // Maroon
        "#FF00FF", // Magenta
        "#800000", // Maroon
        "#4B0082", // Indigo
        "#FFFF00", // Yellow
        "#00FF00", // Lime
        "#EEEE00", // Light Goldenrod Yellow
        "#FF7F00", // Coral Red
        "#9932CC", // Dark Orchid
        "#00FFFF", // Cyan
        "#20B2AA", // Light Sea Green
        "#FFA07A", // Light Salmon
        "#87CEEB", // Sky Blue
        "#FF0000", // Red
        "#C71585", // Medium Violet Red
        "#90EE90", // Light Green
        "#4169E1", // Royal Blue
        "#FFD700", // Gold
        "#808000", // Olive
        "#00C5CD", // Medium Aquamarine
        "#F08080", // Light Coral
        "#E9967A", // Dark Salmon
        "#B22222", // Fire Brick Red
        "#696969", // Dim Gray
        "#8B0000", // Dark Red
        "#B8860B", // Dark Goldenrod
      ]
};

export const DarkColours = {
    'Dark':[
        "#000000", // Black
        "#141414", // Very dark gray
        "#212121", // Charcoal
        "#303030", // Dark gray
        "#3D3D3D", // Dark slate gray
        "#424242", // Dark charcoal
        "#525252", // Slate gray
        "#616161", // Medium dark gray

        "#003366", // Midnight blue
        "#004C3F", // Dark green
        "#536878", // Dark slate blue

        "#99A3A4", // Dark gray (bluish)

        "#8B0000", // Dark red
        "#A0522D", // Sienna
        "#C70039", // Deep pink

        "#800080", // Purple
        "#4863A0", // Midnight blue (another shade)
        "#3879AC", // Dodger blue (darker)
        "#2F4F4F", // Dark slate gray (another shade)

        "#333333", // Very dark gray (another shade)
        "#292929", // Charcoal (another shade)
        "#1A1A1A", // Very dark gray (almost black)
        "#000030", // Navy blue
        "#000040", // Dark blue

        "#000C23", // Dark Midnight Blue
        "#1B1B1B", // Dark Charcoal
        "#282828", // Very Dark Gray
        "#001F3F", // Dark Navy Blue

      ]
};

export const ColourFull={
    'custom':['#94bfac','#5b9291','#3b6879','#264d7e','#1f3057','#2a283d','#3a73a9','#173679','#1c5680','#2c3e75',
              '#8cc5bb','#78adc2','#3f687d','#1f4b61','#5f88c1','#2458af','#135b75','#a7c6eb','#64a0aa','#4f81c5',
              '#bbc9a5','#bcd890','#96bf65','#698b47','#757639','#4b5729','#507d3a','#6a7031','#49523a','#3e4630',
              '#406a28','#33533b','#254432','#428b64','#4f5241','#44945e','#476a4c','#8fc693','#2e4c1e','#364a20',
              '#87965a','#3b3629','#68ab77','#506b52','#7e8f6e','#6b6f5a','#5f5c4b','#4f5138','#feec04','#fef963',
              '#fef96a','#9e7339','#4c4a3c','#7b6b4f','#fced96','#fdf07a','#e9bb43','#fdd906','#fcc808','#f6c870',
              '#dbac50','#d4b97d','#ac7c42','#fde706','#cec093','#f4f0bd','#f5e7a1','#fef6bf','#dd7b00','#feeba8',
              '#bba38a','#eedfa5','#e8c88f','#e6c18d','#cfb48a','#e4cf93','#b2a788','#f3d163','#93612b','#74542f',
              '#5c422e','#402d21','#a86c29','#61361e','#a89177','#845b4d','#564b47','#464334','#753b1e','#c98a71',
              '#a65341','#83422b','#774430','#f3b28b','#67403a','#693b3f','#745f46','#613339','#fbded6','#e8a1a2',
              '#bd8f56','#793932','#8d5b41','#573320','#59493e','#bb3016','#dd3420','#c41c22','#d21e2b','#8b1a32',
              '#471b21','#982d57','#ef841e','#dd3524','#fb9c06','#a83c19','#d04e09','#e45523','#f24816','#a0a9aa',
              '#bec0b8','#9d9d7e','#7a838b','#a5ad98','#9aaa9f','#6b7477','#424c53','#6f7264','#525b55','#5f7682',
              '#8e9b9c','#6c7377','#667563','#566164','#484837','#282b2f','#4e5355','#a9b7b9','#676f76','#7b93a3',
              '#88918d','#909a92','#b6d3cc','#6e4a75','#c9a8ce','#deded2','#b5b8b3','#788585','#385552','#f7d0cf',
              '#a97681','#69474f','#3f4042','#fdeee6','#ead5ca','#a5978c','#facabc','#ac786b','#704a3f','#b05b4b',
              '#8b4131','#ffece0','#f77d64','#e1553e','#f7d3aa','#9f7159','#634739','#cd864a','#8f583d','#ffc882',
              '#ff9b61','#bc6e3c','#fff7e3','#e9dac3','#a69c8e','#6a645d','#3b3c3d','#fff6d6','#dbb17a','#9d7c4d',
              '#624f3e','#f8bf2c','#d8d6cd','#9f9e99','#606667','#fffce4','#e0d6b4','#a5a086','#6f6b58','#3a3830',
              '#fff5d0','#fae6a8','#ccb66f','#594f31','#d0b842','#988c38','#fef6b3','#ffeb75','#f2e428','#fcfae0',
              '#dddcb7','#a3a789','#696c5a','#3a3e33','#dbdd9f','#49502d','#8f9d38','#626e19','#d2dd49','#a3bd42',
              '#f1fbe9','#acbfab','#3e5041','#32382b','#74b979','#448d60','#c5ddd6','#648887','#264f52','#4b8e90',
              '#d6dfdc','#9ca4a5','#6a6f73','#333335','#fdfff8','#a2b7be','#3b5264','#5c869d','#e5f9f4','#bde1e9',
              '#65a2c3','#2569a1','#ccdcec','#7489a6','#29374b','#3b5780','#72a2d9','#f8f6ee','#dbd9da','#847e9f',
              '#524f81','#e5d1db','#684a6b','#000000','#ffffff','#fee7d5','#eda672','#4b2c17','#efc289','#e9eeac',
              '#5e6b1b','#febb75','#d4e273','#97b5d5','#afadd5','#cf3b21','#c71c2c','#6a2c39','#f99e2c','#ffcb05',
              '#5da039','#c0e1c2','#9dd29c','#97d5c8','#096eb2','#014782','#90538f',]
}

export const IntToRGBAColour = (num)=> {
    num >>>= 0;
    var b = num & 0xFF,
        g = (num & 0xFF00) >>> 8,
        r = (num & 0xFF0000) >>> 16,
        a = ((num & 0xFF000000) >>> 24) / 255;
    return "rgba(" + [r, g, b, a].join(",") + ")";
}


export const IntToHexColour = (num)=> {
    return "#"+("0".repeat(6)+(Math.pow(16,6)+num).toString(16)).slice(-6);
}
export const HexToIntColour = (num)=> {num=num.slice(0,7);
    return parseInt(num.slice(-6),16)-Math.pow(16,6);
    //return parseInt(num.replace(/^#([\da-f])([\da-f])([\da-f])$/i,'#$1$1$2$2$3$3').substring(1), 16)*-1;
}

