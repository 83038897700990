import { useState } from 'react';

export const useToken = () => {

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };
  const removeToken = () => {
    localStorage.removeItem('token');
    setToken(null);
  }

  return {
    removeToken: removeToken,
    setToken: saveToken,
    token
  }
}