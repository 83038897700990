

import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine,RiSwap3Line } from 'react-icons/ri';
import { GiLouvrePyramid } from 'react-icons/gi';
import { AiOutlineCalendar, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiShoppingBag, FiEdit, FiPieChart} from 'react-icons/fi';
import { BsKanban, BsBarChart} from 'react-icons/bs';
import { MdDashboard,MdManageSearch,MdFastfood,MdOutlineMenuBook     } from 'react-icons/md';
import { FaUserFriends,FaQrcode } from "react-icons/fa";

import {serverAddress,serverPORT} from "../tools/config"

export const sidebarData = [
  {
    title: '',
    links: [
      {
        name: 'dashboard',
        link: 'dashboard',
        icon: <MdDashboard />,
      },
      {
        name: 'orders',
        link: 'orders',
        icon: <MdFastfood  />,
      },
      {
        name: 'pos menu',
        link: 'posmenu',
        icon: <MdOutlineMenuBook  />,
      },/*
      {
        name: 'pos menu Sort',
        link: 'possort',
        icon: <RiSwap3Line  />,
      },*/
      {
        name: 'qr-menu',
        link: 'qrmenu',
        icon: <FaQrcode  />,
      },
      {
        name: 'staff',
        link: 'staff',
        icon: <FaUserFriends  />,
      },
      {
        name: 'logs',
        link: 'logs',
        icon: <MdManageSearch  />,
      },
    ],
  },/*
  {
    title: 'Apps',
    links: [
      {
        name: 'calendar',
        icon: <AiOutlineCalendar />,
      },
      {
        name: 'kanban',
        icon: <BsKanban />,
      },
      {
        name: 'editor',
        icon: <FiEdit />,
      },
      {
        name: 'color-picker',
        icon: <BiColorFill />,
      },
    ],
  },
  {
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
      },
    ],
  },*/
];