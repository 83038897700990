import React,{useEffect,useState,useRef} from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';

import { staffData, staffGrid,staffLevel,staffEdit,staffAdd,staffDelete } from "../../data/staff";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import validator from "validator";


const Logs = () => {
  const { currentMode } = useStateContext();
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";


    return(
      <div className={"m-2 md:mx-5 mt-2 p-2 md:p-5 rounded-2xl " + bgcolor} >
      <Header title="Logs" /> 
      
    </div>
    )
  }
  
  export default Logs;
  