import React from 'react';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

export const SimpSpiner =(text)=>{//thats for DialogUtility
    return (       
        `<div>${text}</div>
        <div class="flex items-center justify-center mt-5">
       <div id="SimpSpiner"
  class=" inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
  role="status" style="visibility:hidden">
  <span
    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
    >Loading...</span
  >
</div></div>`)
        
}

export const ShowToast=(obj={title:'',content:'',type:''})=>{
  if(obj.content==='')return;
  ToastUtility.show({
      title: obj.title||'Info',
      content: obj.content,
      cssClass:obj.type||'info',
      timeOut: 5000,//5sn
      position: { X: 'Right', Y: 'Top' },
      showCloseButton: true,
  });
}