import React from "react";

const ButtonIcon = ({bgColor, color, bgHoverColor, size, text, borderRadius, width, onClick=() => {}}) => {
    
    return (
        <button
        onClick={onClick}
        style={{ backgroundColor: bgColor, color, borderRadius }}
        className={` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
      >{text}
      </button>
    );
  };
  
    
  
  
  export default ButtonIcon;