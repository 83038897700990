import React,{useEffect,useState,useRef} from "react";
import { useNavigate } from "react-router-dom";

import { DropDownListComponent  } from '@syncfusion/ej2-react-dropdowns';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';
import validator from "validator";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";

import { cataloguesData,entitiesData,entityButtonsData,entityGrid, entityDelete,entityEdit,entityAdd,tileRender,globalTypeData } from "../../data/posmenu";
import { Colours,MenuColumns,MenuLines } from "../../tools/menuEntity";
import "./../CSS/POSMenu.css"




const POSCatalogue = () => {
  const gridcomp = useRef();
  const compBox = useRef();
  const confirmObj = useRef();
  const navigate = useNavigate();

  const [CatalogueData, setCatalogue] = useState([]);


  const { currentMode } = useStateContext();
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  

let getCatalogues = () => { 
  cataloguesData((dataorder)=>{ 
    setCatalogue(dataorder);
    

  });     
}

useEffect(() => {
  getCatalogues();
  
}, []);


//<button className="e-btn bg-indigo-300 pr-2 drop-shadow-md px-6 pb-2  pt-2.5 font-medium uppercase">Reset</button>

    return(
      <div className={"m-2 md:mx-5 mt-2 p-2 md:p-5 rounded-2xl " + bgcolor} >
        <Header title="POS Catalogue" />   
        

    </div>
    )
  }
  
  export default POSCatalogue;
  