import React from "react";
import {fullServerAddress} from "../tools/config"


export const PiToTill = (fn=()=>{})=>{  
    fetch(fullServerAddress + '/api/menu/SyncPiToTill', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => res.json())
        .then((data) => { 
            fn(data);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });

}

export const TillToPi = (fn=()=>{})=>{  
    fetch(fullServerAddress + '/api/menu/SyncTillToPi', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => res.json())
        .then((data) => { 
            fn(data);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });

}
