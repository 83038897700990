// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.syncfusion.com/ej2/22.1.34/tailwind.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*https://cdn.syncfusion.com/ej2/material.css
https://cdn.syncfusion.com/ej2/22.1.34/material3.css
*/
.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

#preview {
  background: transparent url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png") no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;;CAEC;AACD;EACE,mDAAmD;AACrD;AACA;;EAEE,cAAc;AAChB;AACA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;;EAEE,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ;AACA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;AACA;EACE,6BAA6B;AAC/B;;AAEA,wBAAwB;;AAExB;EACE,+GAA+G;EAC/G,qBAAqB;EACrB,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;;;;;;;;EAQE,YAAY;AACd","sourcesContent":["@import url(\"https://cdn.syncfusion.com/ej2/22.1.34/tailwind.css\");\n/*https://cdn.syncfusion.com/ej2/material.css\nhttps://cdn.syncfusion.com/ej2/22.1.34/material3.css\n*/\n.sidebar {\n  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;\n}\n.nav-item,\n.navbar {\n  z-index: 10000;\n}\n@media screen and (max-width: 800px) {\n  .sidebar {\n    z-index: 10000000;\n  }\n}\n\n.e-dlg-center-center,\n.e-quick-popup-wrapper.e-device {\n  z-index: 1000000 !important;\n}\n\n::-webkit-scrollbar {\n  width: 6px;\n}\n::-webkit-scrollbar-thumb {\n  background-color: rgb(216, 216, 216);\n  border-radius: 40px;\n}\n::-webkit-scrollbar-track {\n  background-color: transparent;\n}\n\n/* color-picker style  */\n\n#preview {\n  background: transparent url(\"https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png\") no-repeat;\n  display: inline-block;\n  height: 80px;\n  margin: 10px 0;\n  min-width: 300px;\n  max-width: 600px;\n  background-color: #008000;\n}\n\n.e-input-group:not(.e-float-icon-left),\n.e-input-group.e-success:not(.e-float-icon-left),\n.e-input-group.e-warning:not(.e-float-icon-left),\n.e-input-group.e-error:not(.e-float-icon-left),\n.e-input-group.e-control-wrapper:not(.e-float-icon-left),\n.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),\n.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),\n.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
