import React, { useState }  from "react";
import { useNavigate } from 'react-router-dom';
import {default as Button } from "../../components/Button";
import { useStateContext } from "../../contexts/ContextProvider";
import loginImage from "../../assets/login.svg";
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import PropTypes from 'prop-types';

const Login = ({ setToken }) => {
    
    const { currentColor } = useStateContext();
    const [email, setEmail] = useState('asb@sims.com');
    const [password, setPassword] = useState('1234qwer');    
   // const navigate = useNavigate();

   let toastObj;
   let position = { X: 'Right' };
   function ShowToast(content) {
    toastObj.show({
      title: 'User Information', content: content,
      icon: 'e-warning',
  });
  }


    async function loginUser(credentials) {
        return fetch('http://localhost:8080/user/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(credentials)
        })
        
        .then(async (res) => {
          let x = await res.json();
          if (res.status === 200) {
            return (x.token);
          } else {
            throw new Error(x.message);
          }
        })
        .catch(err => {
          ShowToast('server Error!!')
        });
      }


    const handleSubmit = async e => {
    e.preventDefault();
      try {
        const token = await loginUser({
          email,
          password
          });          
        setToken(token);  
        window.location.reload(false);
        
      } catch {
        ShowToast('Invalid email or password!');
      }
    };
    
    return (
        <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
      <div className="md:w-1/3 max-w-sm">
        <img
          src={loginImage}
          alt="Sample image"
        />
      </div>
      <form className="md:w-1/3 max-w-sm">
      <div >       
        <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
          <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
           Login Form
          </p>
        </div>
        <label>
                Email Address:
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
          type="text"
          value={email}
                  onChange={(e) => setEmail(e.target.value)}
        /></label>
         <label>
                Password:
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="password"
          value={password}
                  onChange={(e) => setPassword(e.target.value)}
          
        /></label>
        <div className="mt-4 flex justify-between font-semibold text-sm">
          <ToastComponent ref={(toast) => { toastObj = toast; }} id='toast_default' position={position} ></ToastComponent>
          
        </div>
        <div className="text-center md:text-left">
        <Button color="white" bgColor={currentColor}  text="Login" borderRadius="10px" width="full" onClick={handleSubmit}  />
          
        </div>
      </div></form>
    </section>
  );
};

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }
export default Login;