import React from "react";
import { IntToHexColour,HexToIntColour } from "../tools/menuEntity";
import { VscSymbolColor } from "react-icons/vsc";
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import { Colours} from "../tools/menuEntity";
import {fullServerAddress} from "../tools/config"
import { TbAddressBook,TbAddressBookOff  } from "react-icons/tb";
import {toDecimal,toCurrency} from "../tools/currency"

/*


*/
export const tileRender =(args)=>{
  args.element.classList.add("rounded-lg");
  args.element.classList.add("w-10");
  args.element.classList.add("h-10");
  args.element.classList.add("m-1");    
}
export const changePaletteColour=(args)=>{
  //console.log(args.target.element.parentElement.parentElement.style.backgroundColor)
  args.target.element.parentElement.parentElement.style.backgroundColor=  args.value;
}

const gridColourBox=(props)=>{   
  let selectedColour=props.PanelColor,colorPicker;
  return (<div className="w-16 h-6 border rounded-md" id='colorPalette' style={{backgroundColor:selectedColour}} >
  </div>);
  }
const gridPrinterBox=(props)=>{
  return (<button><div >{props.GlobalTypeName}</div></button>);
}

export const entityGrid = [
  {
      field: 'EntityName',
      headerText: 'Category Name',
      textAlign: 'Left',
      width: '150',
      allowEditing : false,
  },
  {
      field: 'GlobalTypeName',
      headerText: 'Print Name',        
      textAlign: 'Center',
      width: '150',
      allowEditing : false,
      template: gridPrinterBox,
  },
  {
      field: 'ButtonColor',
      headerText: 'Default Colour',
      textAlign: 'Center',
      width: '100',
      template: gridColourBox,
  },
  {
      field: 'IID',
      width: '0',
      IsPrimaryKey: true,
      Visible: false,
      allowEditing : false,
  },
];


  const gridButtonColourBox=(props)=>{
    return (<div className="w-12 h-6 border px-1 rounded" style={{backgroundColor:props.ButtonColor,color:props.ForeColor}} >
    Text
    </div>);
    }
  const gridPriceTakeaway=(props)=>{
    return (<div >
      
      {(props.PadFlag===0)?toCurrency(props.DirectSalePrice):'PAD Item'} {props.DirectSaleTaxPercent>0?<TbAddressBook   className="w-6 h-6 inline"/>:<TbAddressBookOff className="w-6 h-6 inline" />} 
      
      </div>);
    }
  const gridPriceInHouse=(props)=>{
    return (<div >
      {(props.PadFlag===0)?toCurrency(props.InHousePrice):'PAD Item'} {props.InHouseTaxPercent>0?<TbAddressBook  className="w-6 h-6 inline" />:<TbAddressBookOff  className="w-6 h-6 inline" />} 
      </div>);
    }

export const entityButtonGrid = [
  {
      field: 'EntityButtonName',
      headerText: 'Button Name',
      textAlign: 'Left',
      width: '150',
      allowEditing : false,
  },{
    field: 'ButtonColor',
    headerText: 'Button Colour',
    textAlign: 'Left',
    width: '50',
    allowEditing : false,
    template: gridButtonColourBox,
},
  {
      field: 'PriceTakeaway',
      headerText: 'TakeAway Price',
      textAlign: 'Right',
      width: '100',
      template: gridPriceTakeaway,
  },
  {
      field: 'PriceInHouse',
      headerText: 'TakeAway Price',
      textAlign: 'Right',
      width: '100',
      template: gridPriceInHouse,
  },
  {
      field: 'IID',
      width: '0',
      IsPrimaryKey: true,
      Visible: false,
      allowEditing : false,
  },
];


export const globalTypeGrid = [
  {
      field: 'GlobalTypeName',
      headerText: 'Cateegory Name',
      textAlign: 'Center',
      width: '150',
      allowEditing : false,
  },
  {
      field: 'PrimaryPrinter',
      headerText: 'Primary Print Name',
      width: '100',
      allowEditing : false,
  },
  {
      field: 'SecondaryPrinter',
      headerText: 'Secondary Print Name',
      width: '100',
      allowEditing : false,
  },
  {
      field: 'ThirdaryPrinter',
      headerText: 'Thirdary Print Name',
      width: '100',
      allowEditing : false,
  },
  {
      field: 'IID',
      width: '0',
      IsPrimaryKey: true,
      Visible: false,
      allowEditing : false,
  },
  {
      field: 'PrimaryPrinterIID',
      width: '0',
      Visible: false,
      allowEditing : false,
  },
  {
      field: 'SecondaryPrinterIID',
      width: '0',
      Visible: false,
      allowEditing : false,
  },
  {
      field: 'ThirdaryPrinterIID',
      width: '0',
      Visible: false,
      allowEditing : false,
  },
];






















/**
 * 
 * getting data
 */

export const cataloguesData = (fn=()=>{})=>{  
    fetch(fullServerAddress + '/api/menu/GetCatalogues', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => res.json())
        .then((data) => {
            let x = data.data.map((item, index) => {
                return {
                    CatalogName: item.CatalogName,
                    IID:item.IID,
                };
            });

          //console.log(x)
          fn(x);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });

}


export const entitiesData = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/GetEntities/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                  EntityName: item.EntityName,
                  IID:item.IID,
                  PanelColor:IntToHexColour(item.PanelColor),
                  ButtonHeight:item.ButtonHeight,
                  ButtonWidth:item.ButtonWidth,
                  GlobalTypeName:item.GlobalTypeName,
                  CatalogIID:item.ParentCatalogIID,
                  EntityType:item.EntityType,


              };
          });

        //console.log(x)
        fn(x);
       })
     .catch(err => {
        //console.log(err)
        //ShowToast('server Error!!')
      });

}

export const entityData = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/GetEntity/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                  EntityName: item.EntityName,
                  IID:item.IID,
                  PanelColor:IntToHexColour(item.PanelColor),
                  ButtonHeight:item.ButtonHeight,
                  ButtonWidth:item.ButtonWidth,
                  GlobalTypeName:item.GlobalTypeName,
                  GlobalTypeIID:item.GlobalTypeIID,
                  CatalogIID:item.ParentCatalogIID,
                  EntityType:item.EntityType,
              };
          });

        //console.log(x)
        fn(x[0]);
       })
     .catch(err => {
        //console.log(err)
        //ShowToast('server Error!!')
      });

}


export const entityButtonsData = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/GetEntityButtons/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                  EntityButtonName: item.EntityButtonName,
                  IID:item.IID,
                  CatalogIID:item.ParentCatalogIID,
                  ButtonColor:IntToHexColour(item.ButtonColor),
                  ForeColor:IntToHexColour(item.ForeColor),
                  DirectSaleTaxPercent:item.DirectSaleTaxPercent,
                  DirectSalePrice:item.DirectSalePrice,
                  InHouseTaxPercent:item.InHouseTaxPercent,
                  InHousePrice:item.InHousePrice,
                  ButtonType:item.ButtonType,
                  PadFlag:item.PadFlag,
              };
          });

        //console.log(x)
        fn(x);
       })
     .catch(err => {
        //console.log(err)
        //ShowToast('server Error!!')
      });

}

export const entityButtonData = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/GetEntityButton/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                  EntityButtonName: item.EntityButtonName,
                  IID:item.IID,
                  CatalogIID:item.ParentCatalogIID,
                  ButtonColor:IntToHexColour(item.ButtonColor),
                  ForeColor:IntToHexColour(item.ForeColor),
                  DirectSaleTaxPercent:item.DirectSaleTaxPercent,
                  DirectSalePrice:toDecimal(item.DirectSalePrice),
                  InHouseTaxPercent:item.InHouseTaxPercent,
                  InHousePrice:toDecimal(item.InHousePrice),
                  ButtonType:item.ButtonType,
                  PadFlag:item.PadFlag,
              };
          });

        //console.log(x)
        fn(x[0]);
       })
     .catch(err => {
        //console.log(err)
        //ShowToast('server Error!!')
      });

}

export const entityButtonSubsData = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/GetEntityButtonSubs/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
            return {
                EntityButtonName: item.EntityButtonName,
                IID:item.IID,
                CatalogIID:item.ParentCatalogIID,
                ButtonColor:IntToHexColour(item.ButtonColor),
                ForeColor:IntToHexColour(item.ForeColor),
                DirectSaleTaxPercent:item.DirectSaleTaxPercent,
                DirectSalePrice:item.DirectSalePrice,
                InHouseTaxPercent:item.InHouseTaxPercent,
                InHousePrice:item.InHousePrice,
                PadFlag:item.PadFlag,
              };
          });

        //console.log(x)
        fn(x);
       })
     .catch(err => {
        //console.log(err)
        //ShowToast('server Error!!')
      });

}





export const entityAdd = (obj,fn=()=>{})=>{
  obj.PanelColor=HexToIntColour(obj.PanelColor)  
  fetch(fullServerAddress + '/api/menu/AddEntity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {            
        fn(data.data);
     })
   .catch(err => {
    console.log(err)
      //ShowToast('server Error!!')
    });

  }


export const entityEdit = (obj,fn=()=>{})=>{ 
  obj.PanelColor=HexToIntColour(obj.PanelColor)
  fetch(fullServerAddress + '/api/menu/EditEntity', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {            
        fn(data.data);
     })
   .catch(err => {
      //ShowToast('server Error!!')
    });
}
export const entityCopy = (obj,fn=()=>{})=>{
  obj.PanelColor=HexToIntColour(obj.PanelColor)
  fetch(fullServerAddress + '/api/menu/CopyEntity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {
        fn(data.data);
     })
}

export const entityDelete = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/DeleteEntity/', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iid}),
  })
  .then((res) => res.json())
  .then((data) => {            
      fn(data.data);
   })
 .catch(err => {
    //ShowToast('server Error!!')
  });

}


export const entityButtonAdd = (obj,fn=()=>{})=>{
  obj.ButtonColor=HexToIntColour(obj.ButtonColor);
  obj.ForeColor=HexToIntColour(obj.ForeColor);
  fetch(fullServerAddress + '/api/menu/AddEntityButton', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {            
        fn(data.data);
     })
   .catch(err => {
    console.log(err)
      //ShowToast('server Error!!')
    });

  }


export const entityButtonEdit = (obj,fn=()=>{})=>{ 
  obj.ButtonColor=HexToIntColour(obj.ButtonColor);
  obj.ForeColor=HexToIntColour(obj.ForeColor);
  fetch(fullServerAddress + '/api/menu/EditEntityButton', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {            
        fn(data.data);
     })
   .catch(err => {
      //ShowToast('server Error!!')
    });

}

export const entityButtonDelete = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/DeleteEntityButton/', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iid}),
  })
  .then((res) => res.json())
  .then((data) => {            
      fn(data.data);
   })
 .catch(err => {
    //ShowToast('server Error!!')
  });

}



/**iid:catalogueIID */
export const globalTypesData = (iid,fn=()=>{})=>{

  fetch(fullServerAddress + '/api/menu/GetGlobalTypes/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                GlobalTypeName: item.GlobalTypeName,
                IID:item.IID,
                PrimaryPrinter:item.PrimaryPrinter,
                PrimaryPrinterIID:item.PrimaryPrinterIID,
                SecondaryPrinter:item.SecondaryPrinter,
                SecondaryPrinterIID:item.SecondaryPrinterIID,
                ThirdaryPrinter:item.ThirdaryPrinter,
                ThirdaryPrinterIID:item.ThirdaryPrinterIID,
              };
          });

        //console.log(x)
        fn(x);
       })
     .catch(err => {
        //ShowToast('server Error!!')
      });

}


/**iid:catalogueIID */
export const globalTypeData = (iid,fn=()=>{})=>{

  fetch(fullServerAddress + '/api/menu/GetGlobalType/'+iid, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                GlobalTypeName: item.GlobalTypeName,
                IID:item.IID,
                PrimaryPrinter:item.PrimaryPrinter,
                PrimaryPrinterIID:item.PrimaryPrinterIID,
                SecondaryPrinter:item.SecondaryPrinter,
                SecondaryPrinterIID:item.SecondaryPrinterIID,
                ThirdaryPrinter:item.ThirdaryPrinter,
                ThirdaryPrinterIID:item.ThirdaryPrinterIID,
              };
          });

        //console.log(x)
        fn(x);
       })
     .catch(err => {
        //ShowToast('server Error!!')
      });
}



export const globalTypeAdd = (obj,fn=()=>{})=>{    
  fetch(fullServerAddress + '/api/menu/AddGlobalType', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {            
        fn(data.data);
     })
   .catch(err => {
    console.log(err)
      //ShowToast('server Error!!')
    });
  }

export const globalTypeEdit = (obj,fn=()=>{})=>{ 
  fetch(fullServerAddress + '/api/menu/EditGlobalType', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj),
    })
    .then((res) => res.json())
    .then((data) => {            
        fn(data.data);
     })
   .catch(err => {
      //ShowToast('server Error!!')
    });
}

export const globalTypeDelete = (iid,fn=()=>{})=>{
  fetch(fullServerAddress + '/api/menu/DeleteGlobalType/', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({iid}),
  })
  .then((res) => res.json())
  .then((data) => {            
      fn(data.data);
   })
 .catch(err => {
    //ShowToast('server Error!!')
  });
}

export const printersData = (fn=()=>{})=>{

  fetch(fullServerAddress + '/api/menu/GetPrinters/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => res.json())
      .then((data) => {
          let x = data.data.map((item, index) => {
              return {
                IID:item.IID,
                PrinterName:item.ApplicationName,
                PrinterType:item.PrinterType,
              };
          });

        //console.log(x)
        fn(x);
       })
     .catch(err => {
        //ShowToast('server Error!!')
      });
}
//GlobalTypes

