import React from "react";
import {fullServerAddress} from "../tools/config"

export const staffLevel = {
    0: {
        Status: 'Standard',
        StatusBg: '#CC3300',
    },
    1: {
        Status: 'Manager',
        StatusBg: '#2F5233',
    },
    2: {
        Status: 'Wise Manager',
        StatusBg: '#00CC66',
    }
};

export const gridStaffLevel=(props)=>{
    let xy = staffLevel[props.AccessLevel];
    return (
        <span style={{ background: xy.StatusBg }} className="text-white py-1 px-2 capitalize rounded text-md" >{xy.Status}</span>
    );
}


export const gridStaffPassword=(props)=>{
    
    return (
        <button className="text-md " onClick={showHidePass} value={props.UserPassword}  >{props.UserPasswordShadow}</button>
    );
}

const showHidePass = (obj) =>{   
    let t = obj.target.innerText;
    obj.target.innerText=obj.target.value;
    setTimeout(function() {
    obj.target.innerText=t
    }, 3000,obj,t);
}

export const staffGrid = [
    {
        field: 'UserName',
        headerText: 'User Name',
        textAlign: 'Left',
        width: '150',
        allowEditing : false,
    },
    {
        field: 'UserPasswordShadow',
        headerText: 'Password',
        textAlign: 'Center',
        width: '150',
        template: gridStaffPassword,
    },
    {
        field: 'AccessLevel',
        headerText: 'Access Level',        
        textAlign: 'Center',
        width: '150',
        allowEditing : false,
        template: gridStaffLevel,
    },
    {
        field: 'IID',
        width: '0',
        IsPrimaryKey: true,
        Visible: false,
        allowEditing : false,
    },
    {
        field: 'UserPassword',
        width: '0',
        IsPrimaryKey: true,
        Visible: false,
        allowEditing : false,
    },
];

/*"success":obj.success,
  "message":obj.message,
  "code":obj.code,
  "data":obj.data*/

export const staffData = (fn=()=>{})=>{

    fetch(fullServerAddress + '/api/staff/GetStaff', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => res.json())
        .then((data) => {
            let x = data.data.map((item, index) => {
                return {
                    UserName: item.UserName,
                    UserPasswordShadow: item.UserPassword.replace(/./g,'*'),
                    UserPassword: item.UserPassword,
                    AccessLevel: item.AccessLevel,
                    IID:item.IID,
                };
            });

          //console.log(x)
          fn(x);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });

}


export const staffEdit = (iid,username,password,level,fn=()=>{})=>{

    fetch(fullServerAddress + '/api/staff/EditStaff', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({iid,username,password,level}),
        })
        .then((res) => res.json())
        .then((data) => {            
            fn(data.data);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });
}

export const staffAdd = (username,password,level,fn=()=>{})=>{
    fetch(fullServerAddress + '/api/staff/AddStaff', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({username,password,level}),
        })
        .then((res) => res.json())
        .then((data) => {            
          console.log(data)
          fn(data.data);

         })
       .catch(err => {
          //ShowToast(err+' server Error!!')
          //console.log(err)
        });
}

export const staffDelete = (iid,fn=()=>{})=>{

    fetch(fullServerAddress + '/api/staff/DeleteStaff', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({iid}),
        })
        .then((res) => res.json())
        .then((data) => {            
            fn(data.data);
         })
       .catch(err => {
          //ShowToast('server Error!!')
        });
}
