import React,{useEffect,useState,useRef} from "react";
import { useNavigate,useParams  } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Sort,
  Filter,
  Page,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { DropDownListComponent  } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent,DialogUtility } from '@syncfusion/ej2-react-popups';

import { globalTypesData,globalTypeAdd,globalTypeEdit, globalTypeDelete,globalTypeGrid,printersData } from "../../data/posmenu";
import { Header } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import validator from "validator";


const POSGlobalType = () => {
  const [GTData, setGTData] = useState([]);
  const [PrinterData, setPrinterData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedFirstP, setSelectedFirstP] = useState([]);
  const [selectedSecondP, setSelectedSecondP] = useState([]);
  const [selectedThirdP, setSelectedThirdP] = useState([]);

  const gridcomp = useRef();
  const confirmObj = useRef();
  const combFirstP = useRef();
  const combSecondP = useRef();
  const combThirdP = useRef();

  const [visibility, setDialogVisibility] = useState(false);
  const [dialogHeader,setDialogHeader] = useState("");

  const { currentMode } = useStateContext();
  const editSettings = {allowEditOnDblClick: false};
  
  const bgcolor = currentMode === "Dark" ? "bg-gray-700" : "bg-white";

  const { catalogueIID } = useParams();
  const navigate = useNavigate();

  
  let fields = { text: 'PrinterName', value: 'IID' };
  
  const dialogClose = ()=> {
    setDialogVisibility(false);
  }
  const GTChange=()=>{ 
    if(gridcomp.current.selectedRowIndex>=0){
        let selectedRow = (gridcomp.current.getSelectedRecords())[0]
        setDialogHeader("Change The Printable Category");
        setSelectedProduct(selectedRow);

        setSelectedFirstP(selectedRow.PrimaryPrinterIID);
        setSelectedSecondP(selectedRow.SecondaryPrinterIID);
        setSelectedThirdP(selectedRow.ThirdaryPrinterIID);
        
        showGTModal();
    }
  }
  
  const GTNew=()=>{
    setDialogHeader("Add a New Printable Category");     
    cleanModalItems();
    showGTModal();
  }
  const backMenu=()=>{
    navigate("/posmenu/");
  }

  const showGTModal=()=>{   
    var modal = document.getElementById('btnSave');
    if(modal!==undefined && modal.getAttribute('listener') !== 'true') {
      modal.addEventListener('click',saveGT);
      modal.setAttribute('listener', 'true');
      combAddEmpty();
    }
    cleanValidateModalItems();
    setDialogVisibility(true);
  }
  const GTDeleteConfirm = ()=>{
    if(gridcomp.current.selectedRowIndex>=0)
      confirmObj.current = DialogUtility.confirm({      
        //cancelButton: { text: 'Cancel', click: ()=>{console.log("gave up")} },
        closeOnEscape: false,
        content: "When you delete it, there is no option to get it back. Please think twice before deleting anything!",
        okButton: { text: 'DELETE IT', click: GTDeleteEvent.bind(this) },
        showCloseIcon: true,
        title: 'Do you really want to delete it?',

      });    
  }
  const GTDeleteEvent = ()=>{
    if(gridcomp.current.selectedRowIndex>=0){
        let selectedRow = (gridcomp.current.getSelectedRecords())[0]
        globalTypeDelete(selectedRow.IID,()=>{
        setSelectedProduct([]);
        getGT();
        confirmObj.current.hide();
        });
    }
  }

  const GetPrinters=()=>{
    printersData((data)=>{      
      setPrinterData(data);      
    });
  }

  useEffect(() => {
    getGT();
    GetPrinters();
  },[]);

  const cleanValidateModalItems=()=>{
    document.getElementById('errgtName').innerText=('');
    //document.getElementById('errUserName').innerText=('');
  }
  const cleanModalItems=()=>{
    document.getElementById('GTIID').value=''; 
    document.getElementById('gtName').value=''; 
    combFirstP.current.value=''; 
    combSecondP.current.value=''; 
    combThirdP.current.value=''; 
  }

  const validateModalItems=()=>{
    let obj = {
        globalTypeName: document.getElementById('gtName').value.trim(),
        primaryPrinterIID:combFirstP.current.value||'',
        secondaryPrinterIID:combSecondP.current.value||'',
        thirdaryPrinterIID:combThirdP.current.value||'',
        iid: document.getElementById('GTIID').value||'',
        catalogueIID:catalogueIID,
    };
    let err=false;

    if(! validator.isLength(obj.globalTypeName,{ min: 3, max: 10 }) ){
      document.getElementById('errgtName').innerText=('Category Name can only be Alpha Numeric characters. Min:3 and Max:10 characters');
      err=true;
    }
    return err?{}:obj;
  }


    
  const saveGT=()=>{    
    var obj = validateModalItems();
    
    if(obj.globalTypeName===undefined) return false;

    if(gridcomp.current.selectedRowIndex>=0) {
        globalTypeEdit(obj,(x)=>{
            dialogClose();
            getGT();
      });
    }else{
        globalTypeAdd(obj,(x)=>{
            dialogClose();
            getGT();
      });      
      setSelectedProduct([]);
    }
  }
  
  let getGT = () => {    
        globalTypesData(catalogueIID,(dataorder)=>{ 
            setGTData(dataorder);            
    });     
  }

  const combAddEmpty=()=>{
    combFirstP.current.addItem({ PrinterName: 'No Print', IID: '' }, 0);
    combSecondP.current.addItem({ PrinterName: 'No Print', IID: '' }, 0);
    combThirdP.current.addItem({ PrinterName: 'No Print', IID: '' }, 0);
  }

  return(
    <div className={"m-2 md:m-5 mt-24 p-2 md:p-5 rounded-2xl " + bgcolor} >
    <Header title="Printable Categories" />   

    <div className="grid grid-cols-6 gap-1"> 
        <button className="e-btn bg-yellow-300  drop-shadow-md px-2 p-2.5 font-medium uppercase rounded" onClick={backMenu} >back </button>
        
        <button className="e-btn bg-teal-300  drop-shadow-md px-2 p-2.5 font-medium uppercase rounded  col-start-3" onClick={getGT} >LOAD </button>
        <button className="e-btn bg-green-300 drop-shadow-md px-2 p-2.5 font-medium uppercase rounded " onClick={GTNew}    >NEW </button>
        <button className="e-btn bg-sky-300   drop-shadow-md px-2 p-2.5 font-medium uppercase rounded " onClick={GTChange}   >EDIT</button>
        <button className="e-btn bg-red-300   drop-shadow-md px-2 p-2.5 font-medium uppercase rounded " onClick={GTDeleteConfirm}  >DELETE </button>
      
      </div>  
      <br/>
      <GridComponent ref={gridcomp} allowPaging={true} allowSorting={true}  editSettings={editSettings}  dataSource={GTData} >
        <ColumnsDirective>
          {globalTypeGrid.map((item, index) => (
            <ColumnDirective key={index} {...item}  />
          ))}
        </ColumnsDirective>
        <Inject services={[Sort,  Filter, Page,  Toolbar]} />
      </GridComponent>
      
      <DialogComponent isModal={true}  id="modalStaff" showCloseIcon={true} visible={visibility} close={dialogClose} header={dialogHeader} 
      cssClass="e-fixed border-8 border-zing-500" width='450px' >
        <div >
        <form id="form1">
        <div className="mt-10 grid grid-cols-3 gap-x-6 gap-2">   
          <label htmlFor="gtName" 
          className="block text-sm font-medium leading-6 text-gray-900 ">
            Printable Category Name
          </label>
          <input id="gtName" type="text" defaultValue={selectedProduct.GlobalTypeName}
            className="block flex-1 border-1 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 col-span-2 " >
            </input>
          <div id="errgtName" className="col-span-3 text-red-700"/> 

          <label htmlFor="level" 
            className="block text-sm font-medium leading-6 text-gray-900 ">
            First Printer
          </label>
          <DropDownListComponent ref={combFirstP}  dataSource={PrinterData} fields={fields} value={selectedFirstP}
                    className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
           <div></div>                                                           
          <label htmlFor="level" 
            className="block text-sm font-medium leading-6 text-gray-900 ">
            Second Printer
          </label>
          <DropDownListComponent ref={combSecondP}  dataSource={PrinterData} fields={fields} value={selectedSecondP}
                    className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
                                                                      
           <div></div>                                                           
           <label htmlFor="level" 
            className="block text-sm font-medium leading-6 text-gray-900 ">
            Third Printer
          </label>
          <DropDownListComponent ref={combThirdP}  dataSource={PrinterData} fields={fields} value={selectedThirdP}
                    className="block flex-1 border-1 border-slate-200 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"/>
          
          <button type="button" id="btnSave" 
            className="bg-transparent hover:bg-gray-500 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-zing-500 hover:border-transparent rounded w-90 col-span-3"
            >SAVE </button>
          </div>
          <input type="hidden" id="GTIID" defaultValue={selectedProduct.IID} />
            </form>     
            </div>  
        </DialogComponent>

    </div>
  )
}


export default POSGlobalType;