import React from "react";

const Button = ({ bgColor, color, bgHoverColor, textSize, text, borderRadius, width, onClick=() => {}}) => {
  
  return (
    <button
      type="button"  
      onClick={onClick}    
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={` text-${textSize} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {text}
    </button>
  );
};

export default Button;
