import React from "react";
import { registerLicense } from '@syncfusion/ej2-base';

import { BrowserRouter, Routes, Route, Switch  } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Orders,
  Dashboard, 
  Staff, 
  POSMenu,
  POSEntity,
  POSCatalogue,
  POSEntityButton,
  POSEntityButtonSub,
  POSSort,
  QRMenu,
  Logs,
  POSGlobalType,
} from "./pages";
import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";

//import {useToken} from './useToken';


registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXtccHZVRmVeUk1xWkc=');

const App = () => {
 

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode } = useStateContext();
  
  //const { token, setToken } = useToken();  
/*
  if(!token) {
    return <Login setToken={setToken} />
  }
  */
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Setting" position="TopCenter">              
              <button
                type="button"
                className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                style={{ background: currentColor, borderRadius: "50%" }}
                onClick={() => setThemeSettings(true)}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}
              <Routes>
                {/* dashboard  */}
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />

                {/* pages  */}
                <Route path="/orders" element={<Orders />} />

                <Route path="/possort" element={<POSSort />} />
                <Route path="/possort/:entityIID" element={<POSSort />} />
                <Route path="/possort/:entityIID/:entityButtonIID" element={<POSSort />} />
                <Route path="/possort/:entityIID/:entityButtonIID/:entityButtonSubIID" element={<POSSort />} />

                <Route path="/poscatalogue" element={<POSCatalogue />} />
                <Route path="/posmenu/" element={<POSMenu />} />                
                <Route path="/posmenu/:catalogueIID/:entityIID" element={<POSEntity />} />                
                <Route path="/posbutton/:entityIID/:entityButtonIID" element={<POSEntityButton />} />
                <Route path="/posbutton/:entityIID/:entityButtonIID/:entityButtonSubIID" element={<POSEntityButtonSub />} />

                <Route path="/posprint/:catalogueIID" element={<POSGlobalType />} />

                <Route path="/qrmenu" element={<QRMenu />} />
                <Route path="/logs" element={<Logs />} />

                <Route path="/staff" element={<Staff />} />

              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
