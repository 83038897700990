

export const toCurrency = (...value) =>{    
    let v =  (value.reduce((acc,item)=> acc + item,0));
    return v.toLocaleString("en-GB", {style:"currency", currency:"GBP"});
   }
export const toDecimal = (...value) =>{    
    let v =  (value.reduce((acc,item)=> acc + item,0));
    return v.toFixed(2);
   }

