
export const themeColors = [
    {
      name: 'blue-theme',
      color: '#1A97F5',
    },
    {
      color: '#002244',
      name: 'darkblue-theme',
    },
    {
      name: 'green-theme',
      color: '#03C9D7',
    },
    {
      name: 'purple-theme',
      color: '#7352FF',
    },
    {
      name: 'red-theme',
      color: '#FF5C8E',
    },
    {
      name: 'indigo-theme',
      color: '#1E4DB7',
    },
    {
      color: '#FB9678',
      name: 'orange-theme',
    },
    {
      color: '#666666',
      name: 'grey-theme',
    },
  ];